import { IContract, IPricing, IPricingItem } from '../../../../../models';
import { DataGridRenderBodyCellParams } from '@dierbergs-markets/react-component-library';
import * as PG from '@dierbergs-markets/react-feature-library';
import { CorePricingGridColumnFields, CurrencyCellContent, PricingGrid } from '@dierbergs-markets/react-feature-library';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { defaultColors } from '../../../../../styles/variables';
import { PricingGridPricingItem } from '../../../../../utilities/PricingUtilities';
import { ValidationIssue } from '../../../../../classes/ValidationIssue';
import { toDataGridCellMessage } from '../../../../../utilities/DataGridUtilities';
import { pricingValidationService } from '../../../../../services/pricingValidationService';
import { useContractValidationStore } from '../../../../../store/contract/contractValidationStore';
import { ValidationScopes } from '../../../../../models/enums';
import { DataGridCellMessage } from '@dierbergs-markets/react-feature-library';
import { Circle, Inventory, Remove } from '@mui/icons-material';
import PricingSourceDialog from './PricingSourceDialog';
import usePricingGridConfig from './hooks/usePricingGridConfig';
import { useApplicationContextState } from '../../../../../contexts/ApplicationContext';

interface IProps {
  contract: IContract;
  canModify: boolean;
  contractLoading: boolean;
  onPricingEdit: (price: IPricing) => void;
  onRowUpdate: (row: PG.IPricingItem, messages?: DataGridCellMessage) => void;
  onMultiRowUpdate: (rows: PG.IPricingItem[]) => void;
  onPricingItemsUpdate: (pricingIndex: number, items: IPricingItem[]) => void;
  errors?: ValidationIssue[];
}

const PricingGridContainer = memo((props: IProps) => {
  // STATE
  const [sourceDialogSelectedRow, setSourceDialogSelectedRow] = useState<PricingGridPricingItem | null>(null);

  // MISC. HOOKS
  const contractPricingValidationIssues = useContractValidationStore((s) => s.validationIssues.filter((e) => e.scope === ValidationScopes.Pricings));
  const { setHasInternalPricingGridErrors, hasInternalPricingGridErrors, validationIssues, setValidationIssuesByIdentifierScope } =
    useContractValidationStore();
  const { referenceData } = useApplicationContextState();

  // MEMOS
  const externalMessages = useMemo(() => {
    const getMessageUniqueId = (m: DataGridCellMessage) => `${m.rowId}_${m.field}_${m.type}_${m.message}`;

    const combinedCellMessages: DataGridCellMessage[] = [];
    const uniqueCellMessagesSet = new Set<string>();

    //ensure any duplicate messages get filtered out before sending to PricingGrid
    contractPricingValidationIssues.map(toDataGridCellMessage).forEach((cellMessage) => {
      const key = getMessageUniqueId(cellMessage);
      if (!uniqueCellMessagesSet.has(key)) {
        uniqueCellMessagesSet.add(key);
        combinedCellMessages.push(cellMessage);
      }
    });

    return combinedCellMessages;
  }, [contractPricingValidationIssues, validationIssues]);

  const { basePricingGridProps } = usePricingGridConfig({
    contract: props.contract,
    canModify: props.canModify,
    invokePricingEdit,
    updatePricingItems: props.onPricingItemsUpdate,
  });

  // EFFECTS

  // FUNCTIONS

  function mapPricingGridPriceToPricingItem(sku: number, pricingGridPrice?: PG.IPrice): IPricingItem {
    return { sku, multiple: pricingGridPrice?.multiple ?? 0, price: pricingGridPrice?.price, orderSurvey: pricingGridPrice?.orderSurvey ?? false };
  }
  const handleRowUpdate = useCallback(
    (row: PG.IPricingItem) => {
      const rowValidationIssues: ValidationIssue[] = [];

      row.prices.forEach((p: PG.IPrice | undefined, index) => {
        const pricingItem = mapPricingGridPriceToPricingItem(row.item.sku, p);
        const validationResult = pricingValidationService.validatePricingItem(props.contract, pricingItem, index, referenceData);
        rowValidationIssues.push(...validationResult);
      });

      setValidationIssuesByIdentifierScope(row.item.sku.toString(), ValidationScopes.Pricings, rowValidationIssues);

      props.onRowUpdate(row);
    },
    [props.contract, props.onRowUpdate, setValidationIssuesByIdentifierScope]
  );

  function invokePricingEdit(pricing: IPricing) {
    props.canModify && props.onPricingEdit(pricing);
  }

  const handleInternalValidationComplete = useCallback(
    (messages: DataGridCellMessage[]) => {
      const hasErrors = messages.length > 0;
      if (hasInternalPricingGridErrors !== hasErrors) {
        setHasInternalPricingGridErrors(hasErrors);
      }
    },
    [hasInternalPricingGridErrors]
  );

  return (
    <div>
      {basePricingGridProps && (
        <PricingGrid
          {...basePricingGridProps}
          id={'PricingGrid'}
          findReplaceEnabled={true}
          columnOrderable
          maxBodyHeightOffset={496}
          headerRowHeight={160}
          actionConfig={[{ position: 'bottom', actions: ['Columns', 'Sort'], alignment: 'left' }]}
          processRowUpdate={handleRowUpdate}
          processMultiRowUpdate={props.onMultiRowUpdate}
          onInternalValidationComplete={handleInternalValidationComplete}
          preferences={{
            columns: {
              visibility: {
                suggestedRetail: true,
                source: true,
                [CorePricingGridColumnFields.ItemOrderCode]: true,
              },
              order: {
                source: 3.1,
                suggestedRetail: 10.1,
              },
            },
          }}
          additionalColumns={[
            {
              field: 'source',
              headerName: 'Source',
              type: 'string',
              width: 55,
              renderBodyCellContent(params: DataGridRenderBodyCellParams<any, PricingGridPricingItem>) {
                const icon = params.row.source?.shipper ? <Inventory /> : params.row.source ? <Circle /> : <Remove />;

                return (
                  <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => setSourceDialogSelectedRow(params.row)}>
                    {icon}
                    {params.row.sources.length > 1 ? '..' : ''}
                  </div>
                );
              },
            },
            {
              field: 'suggestedRetail',
              headerName: 'Sugg Retail',
              type: 'string',
              width: 95,
              renderBodyCellContent(params: DataGridRenderBodyCellParams<number, PricingGridPricingItem>) {
                if (!params.row.suggestedRetailPrice) return <></>;
                return <CurrencyCellContent value={params.row.suggestedRetailPrice} multiple={params.row.suggestedRetailMultiple} />;
              },
            },
          ]}
          costImplicationGroupedHeaderCellCss={{
            root: {
              backgroundColor: defaultColors.white,
              borderRadius: '16px 16px 0 0',
              outline: `1px solid ${defaultColors.grey}`,
              fontSize: '16px',
              paddingTop: '13px',
              height: '150px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            },
            subRow: {
              marginTop: '10px',
            },
          }}
          messages={externalMessages}
        />
      )}
      <PricingSourceDialog selectedRow={sourceDialogSelectedRow} onClose={() => setSourceDialogSelectedRow(null)} />
    </div>
  );
});

export default PricingGridContainer;
